









































import {Component, Prop, Vue} from "vue-property-decorator";
import RegexUtils from "@/utils/RegexUtils";
import DocumentService from "@/services/DocumentService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import RequestIT2104RequestDTO from "@/dto/archive/RequestIT2104RequestDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import {ifValid} from "@/utils/ComponentUtils";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmployeeService from "@/services/EmployeeService";
import {EmployeeFilter} from "@/dto/payroll/Filters";

const AppModule = namespace("App");

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		}
	},
	components: {PortalSelect}
})
export default class RequestIT2104Modal extends Vue {

	private successful = false;

	private message = "";

	private mock: number | null = null;

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private request = new RequestIT2104RequestDTO();

	@Prop()
	private companyId!: number;

	@Prop()
	private onSaved!: () => void;

	private selectedEmployee: PersonDTO = new PersonDTO();

	private employees: Array<IEmployment> = [];

	mounted() {
		this.request.employerId = this.companyId;
		this.loadEmployees();
	}

	loadEmployees() {
		this.stopLoading();
		const filter = new EmployeeFilter();
		filter.employerId = this.companyId
		filter.pageSize = 100
		EmployeeService.findByFilter(filter).then(
			res => {
				this.employees = res.data.data;
				this.successful = true;
			},
			err => {
				this.errorHandle(err)
			}
		).then(() => this.stopLoading)
	}

	private save(): void {
		ifValid(this, () => {
			this.message = "";
			this.startLoading();
			DocumentService.requestIt2104(this.request).then(
				() => {
					this.successful = true;
					this.onSaved();
					this.$modal.hideAll();
				},
				error => {
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				}
			).then(() => this.stopLoading());
		})
	}

	private errorHandle(error: any): void {
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
		this.successful = false;
	}

}
